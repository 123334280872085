/* eslint-disable max-len */
import { Formik } from "formik";
import styles from "./RequestPayoutPage.module.scss";
import { dashboardSelector } from "@podcasts/store/dashboard"
import { logOut } from "@podcasts/store/user"
import * as yup from "yup";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, InputSelect, CheckBox, Input, RadioButton } from "../../../../../components";
import NumberRating from "../../../../../components/NumberRating/NumberRating";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  getPodcast,
  getRecentlyViewed,
  getRequestPayOut,
  clearSelectedPodcast
} from "@podcasts/store/dashboard"
import { TextArea } from "../../../../../components";
import { requestPayout } from "@podcasts/store/dashboard";
import { userSelector } from "@podcasts/store/user";
import DatePicker from "react-datepicker";
import { useRequestPayment } from "@podcasts/hooks";
import { usePrevious } from "../../../../../hooks/usePrevious";
import { showToastMessage } from "../../../../../utils/common";
import moment from "moment";

export function RequestPayoutPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    podcast,
    recently_viewed: { data = [] },
    loading,
    total_payout_request
  } = useSelector(dashboardSelector) as any;
  const { user } = useSelector(userSelector) as any;

  const { canRequestPayment } = useRequestPayment();

  const [success, setSuccess] = useState(false);
  const [different, setDifferent] = useState(false);
  const [showExternalLink, setShowExternalLink] = useState(false);

  const navigate = useNavigate();

  const { payoutRequestSuccess, payoutRequestError, requestedMoreThanAllowed } = useSelector(dashboardSelector) as any;
  const prevPayoutRequestSuccess = usePrevious(payoutRequestSuccess);
  const prevPayoutRequestError = usePrevious(payoutRequestError);

  useEffect(() => {      
    return () => {
      dispatch(clearSelectedPodcast());
    }
  }, [])

  useEffect(() => {
    if (user && user.role !== "Student") {
      navigate("/podcasts/featured", { replace: true });
    }
  }, [user]);
    

  useEffect(() => {
    if (payoutRequestSuccess && payoutRequestSuccess !== prevPayoutRequestSuccess) {
      navigate("/podcasts/featured", { replace: true });
      showToastMessage(payoutRequestSuccess, "success","BOTTOM_CENTER", true);
    }
  }, [payoutRequestSuccess]);

  useEffect(() => {
    if (payoutRequestError && payoutRequestError !== prevPayoutRequestError) {
      // showToastMessage(payoutRequestError, "error");
      toast.error(payoutRequestError, {
        position: "top-right",
        className: "foo-bar",
        theme: "colored",
        toastId: styles.requestedMoreThanAllowed
      });
    }
    // if (payoutRequestError === "Unauthenticated.") {
    //   dispatch(logOut());
    //   navigate("/login");
    // }
  }, [payoutRequestError]);

  useEffect(() => {
    dispatch(getPodcast(id));
    dispatch(getRecentlyViewed());
    dispatch(getRequestPayOut(id));
  }, []);

  useEffect(() => {
    if (id === "request") {
      setDifferent(true);
    }
  }, [])

  const schema = () =>
    yup.object().shape({
      date_learned: yup.string().required(),
      review: yup.string().required(),
      rating: yup.number().required(),
      podcast_id: yup.number().nullable(),
      is_public: yup.boolean().required(),
      external_podcast_title: yup.string().nullable()
        .when([], {
          is: () =>  showExternalLink,
          then: yup.string().required(),
          otherwise: yup.string().nullable(),
        }),
      external_podcast_url: yup.string().nullable()
        .when([], {
          is: () => showExternalLink,
          then: yup.string().required(),
          otherwise: yup.string().nullable(),
        }),
      social_event: yup.boolean().nullable().default(null),
      social_event_description: yup.string().nullable()
        .when("social_event", {
          is: true,
          then: yup.string().required()
        }),
      request_reason: yup.string().nullable()
        .when([], {
          is: () => requestedMoreThanAllowed,
          then: yup.string().required(),
          otherwise: yup.string().nullable(),
        }),
    });

  const ReactDatePicker = DatePicker as any;
  
  const toggleExternalPodcast = () => {
    setShowExternalLink(!showExternalLink);
    setDifferent(false);
  }

  return (
    <>
      <div
        className={`${styles["request-close"]} d-flex align-items-center justify-content-end`}
      >
        <button className="normalize-btn" onClick={() => !success ? navigate(-1) : navigate("/profile/payments")}>
          <i className="icon-close"></i>
        </button>
      </div>
      <div className={`${styles["request-body"]}`}>
        <h2>Request a payment</h2>
        {podcast && !different && !showExternalLink ? <p>{podcast?.title}</p> : null}
        {!loading ? (
          <section>
            {" "}
            {!canRequestPayment || podcast?.is_payout_request_sent ? (
              <div>You already requested a payment for this podcast</div>
            ) : (
              <section>
                <Formik
                  enableReinitialize
                  initialValues={{
                    date_learned: null,
                    review: "",
                    rating: 1,
                    podcast_id: !different ? podcast?.id : null,
                    is_public: false,
                    reviewed: true,
                    external_podcast_title: null,
                    external_podcast_url: null,
                    request_reason: null,
                    social_event: null,
                    social_event_description: null
                  }}
                  validationSchema={schema}
                  onSubmit={(values, { setSubmitting }) => {
                    dispatch(requestPayout(values));
                    setSuccess(true);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <div className={`${styles["form"]}`}>
                      {!different && !showExternalLink ? (
                        <Link
                          to={"#"}
                          onClick={() => {
                            setDifferent(true);
                            setShowExternalLink(false);
                          }}
                        >
                            Choose a different podcast
                        </Link>
                      ) : null}
                      <div className={`${styles["request-payout"]}`}>
                        {different ? (
                          <InputSelect
                            options={data.map((p: any) => ({
                              value: p.slug,
                              label: p.title,
                            }))}
                            label="Which podcast are you requesting payment for?"
                            placeholder="Your 10 most recently viewed"
                            name="gender"
                            onChange={({ value }: any) => {
                              dispatch(getPodcast(value));
                              setDifferent(false);
                            }}
                            onBlur={handleBlur}
                            value={values.podcast_id}
                            error={
                              errors.podcast_id && touched.podcast_id
                                ? "Please choose a podcast"
                                : null
                            }
                          />
                        ) : null}
                      </div>
                      <div>
                        {user?.approved_for_external_podcasts ? (
                          <div className={`${styles["external-podcast"]}`}>
                            <CheckBox
                              name="approved_for_external_podcasts"
                              label="External Podcast"
                              value={showExternalLink}
                              onChange={toggleExternalPodcast}
                            />
                            {
                              showExternalLink ? (
                                <>
                                  <Input
                                    placeholder="Please enter the title *"
                                    name="external_podcast_title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.external_podcast_title}
                                    error={
                                      errors.external_podcast_title && touched.external_podcast_title
                                        ? "Required"
                                        : null
                                    }
                                  />
                                  <Input
                                    placeholder="Please enter the url *"
                                    name="external_podcast_url"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.external_podcast_url}
                                    error={
                                      errors.external_podcast_url && touched.external_podcast_url
                                        ? "Required"
                                        : null
                                    }
                                  />
                                </> 
                              ) : null
                            }
                          </div>
                        ) : null}

                      </div>
                      <div className={`${styles["podcast-review-date"]}`}>
                        <p>What date did you review this podcast with your mentor?</p>
                        <ReactDatePicker
                          placeholderText="mm/dd/yyyy"
                          // eslint-disable-next-line max-len
                          className={!errors.date_learned ? `${styles["calendar"]}` : `${styles["calendar"]} ${styles["calendar--error"]}`}
                          name="date_learned"
                          selected={values.date_learned}
                          onChange={(date: Date) => {
                            const adjustedDate = moment(date).subtract(date.getTimezoneOffset(), "minutes").toDate();
                            setFieldValue("date_learned", adjustedDate);
                          }}
                          onBlur={handleBlur}
                          minDate={moment().subtract(6, "month").toDate()}
                          maxDate={moment().toDate()}
                        />
                        <p className={`${styles["datepicker-error"]}`}>{errors.date_learned && "Required"}</p>
                        <p>Your mentor will be notified of your payment request.</p>
                      </div>
                      <div className={`${styles["podcast-rating"]}`}>
                        <p>What did you think of this podcast?</p>
                        <NumberRating
                          titleStart="Didn’t love it"
                          onChange={(v: number) => {
                            setFieldValue("rating", v);
                          }}
                          titleEnd="Loved it"
                          name="rating-numbers"
                          value={values.rating}
                        />
                      </div>
                      <div className={`${styles["comments"]}`}>
                        <TextArea
                          rows={7}
                          placeholder="Write any comments you have..."
                          name="review"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.review}
                          error={
                            errors.review && touched.review
                              ? "Required"
                              : null
                          }
                        />
                      </div>
                      <div className={`${styles["review-admin"]}`}>
                        <CheckBox
                          name="is_public"
                          label="Display my comment on the website. This will be reviewed by a Podcast Admin."
                          value={values.is_public}
                          onChange={handleChange}
                        />
                      </div>
                      {
                        (total_payout_request > 0 && total_payout_request % 5 === 0) && (<div className={`${styles["review-admin"]} row`}>
                          <p>
                          Did you attend an in-person Jewish event/learning session in the last 4 weeks?*
                          </p>
                          <div className="col-3 mt-0">
                            <RadioButton 
                              name="social_event" 
                              value={true} label="Yes"
                              checked={values.social_event === true}
                              onChange={(value: any) => {
                                setFieldValue("social_event", true);
                              }}
                            />
                          </div>
                          <div className="col-3 mt-0">
                            <RadioButton 
                              name="social_event" value={false} label="No"
                              checked={values.social_event === false}
                              onChange={(value: any) => {
                                setFieldValue("social_event", false);
                              }}/>
                          </div>
                          {values.social_event !== null &&
                          <div className={`${styles["comments"]}`}>
                            <TextArea
                              rows={7} 
                              name="social_event_description"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.social_event_description}
                              error={
                                errors.review && touched.review
                                  ? "Required"
                                  : null
                              }
                              // eslint-disable-next-line max-len
                              placeholder={`${values.social_event ? "Please elaborate and share your thoughts on the event." : "Please explain/request an excuse."}`}
                            />
                          </div>
                          }
                        </div>)
                      }
                      {/* <div className={`${styles["review-admin"]}`}>
                          <CheckBox
                            name="reviewed"
                            value={values.reviewed}
                            onChange={handleChange}
                            error={
                              errors.reviewed && touched.reviewed
                                ? "Required"
                                : null
                            }
                            label='<p style="margin: 0;text-align: left;line-height:20px;"><span>I reviewed this class 
                            with my mentor *<span/></br><span style="opacity: .5;">You must review the class with 
                            your podcast mentor in order to earn $15</span></p>'
                          />
                        </div> */}

                      {
                        requestedMoreThanAllowed ? (
                          <div className={`${styles["request-reason"]}`}>
                            <Input
                              label="Please enter the reason *"
                              name="request_reason"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.request_reason}
                              error={
                                errors.request_reason && touched.request_reason
                                  ? "Required"
                                  : null
                              }
                            />
                          </div>
                        ) : null
                      }

                      <div className={`${styles["submit-button"]}`}>
                        <Button name="Submit" onClick={() => { 
                          if (values.external_podcast_title) {
                            setFieldValue("podcast_id", null)
                          }
                          handleSubmit();
                        }} />
                      </div>
                    </div>
                  )}
                </Formik>
              </section>
            )}{" "}
          </section>
        ) : null}
      </div>
    </>
  );
}

export default RequestPayoutPage;
