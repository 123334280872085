/* eslint-disable max-len */
// import styles from './Footer.module.scss';
import React from "react";
import styles from "./DonatePage.module.scss";
import rightImg from "../../assets/images/donate_girl.svg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import btnS from "../../components/Button/Button.module.scss";

export function DonatePage() {

  return (
    <>
      <div className={styles["donate-page"]}>
        <Helmet>
          <script
            src='https://podcastfellowship-bloom.kindful.com/embeds/00d0f2b3-2fb6-4719-9960-2253640f37e7/init.js?type=form'
            data-embed-id='00d0f2b3-2fb6-4719-9960-2253640f37e7' data-lookup-type='jquery-selector'
            data-lookup-value='#kindful-donate-form-00d0f2b3-2fb6-4719-9960-2253640f37e7'></script>
        </Helmet>
        <div className="container mobile-container" style={ { position: "relative" }}>
          <section className={styles["top-section"]}>
            <div className={`${styles["row-reverse"]} row`}>
              <div className={`${styles["left-section"]} col-md-6`}>
                <div className={styles["top-text-box"]}>
                  <h1>
                                      Inspire The <br />
                    <span className="orange-yellow-color">Jewish Future</span>
                  </h1>
                  <p className={styles["top-text-subtitle"]}>
                                      Donate to the Podcast Fellowship today.
                  </p>
                  <p className={styles["top-text-descr"]}>
                    The Podcast Fellowship is a project of Podcast Educational Foundation, a 501(c)3 Tax-exempt organization, Tax ID (EIN) #86-3753638
                  </p>
                  <p className={styles["top-text-descr"]}>
                  Your donation is tax-deductible in the United States.
                  </p>
                  <p className={styles["top-text-descr"]}>
                    To donate from outside of the US, please contact us at <a href="mailto:info@podcastfellowship.org">info@podcastfellowship.org</a>
                  </p>
                </div>
                <div className="row d-flex align-items-center">
                  <div className="col-auto">
                     or
                  </div>
                  <div className="col-5">
                    <Link className={btnS["button"]}
                      to="https://www.paypal.com/donate/?business=tuvia%40podcastfellowship.org&cmd=_donations&currency_code=USD&source=url&Z3JncnB0"
                      target="_blank">
                      <p className={btnS["button-text"]}>Donate via Paypal</p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div id='kindful-donate-form-00d0f2b3-2fb6-4719-9960-2253640f37e7' className={`${styles["prices"]} kindful-embed-wrapper`}></div>
              </div>
            </div>
          </section>
        </div>

        <div className={`${styles["right-img-container"]}`} >
          <img src={rightImg} alt="NO IMAGE" />
        </div>
      </div>
    </>

  );
}

export default DonatePage;
