import { useSelector } from "react-redux";
import { tokenSelector } from '@podcasts/store/user';
import { get } from 'lodash';

export const useIsAuthenticated = () => {
    const user = useSelector(state => get(state,'user.user'));
    const token = user?.token;
    const issued_at = user?.issued_at;
    const expires_in = user?.expires_in;
    const x = new Date();
    // const now = (x.getTimezoneOffset() * 60* 1000 + x.getTime()) / 1000;
    const now = Math.floor(Date.now() / 1000);

    return !!token && now < issued_at + expires_in;
}
