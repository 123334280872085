import React, { Fragment, useEffect } from "react";
import { Button  } from "../../components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./HomePage.module.scss";
import { SliderItem } from "./components/SliderItem/SliderItem";
import { SliderFirst } from "./components/SliderFirst/SliderFirst";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { dashboardSelector } from"@podcasts/store/dashboard";
import { getTopics } from "@podcasts/store/dashboard";
import { resetState } from "@podcasts/store/application";
import firstParticipant from "../../assets/images/home/satchel.jpg";
import secondParticipant from "../../assets/images/home/chelsea.jpg";
import thirdParticipant from "../../assets/images/home/alex.jpg";
import sliderBg from "../../assets/images/slider-home-bg.svg";
import applyAndChooseImg from "../../assets/images/home/apply_and_choose.jpg";
import meetImg from "../../assets/images/home/meet.jpg";
import listenImg from "../../assets/images/home/listen.jpg";
import paidImg from "../../assets/images/home/get_paid.jpg";
import PlayIcon from "../../components/PlayIcon/PlayIcon";
import { useIsAuthenticated } from "@podcasts/hooks";
import { SessionTimeout } from "../../components/SessionTimeout/SessionTimeout";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const slides = [
  {
    image:
    firstParticipant,
    name: "Satchel Bootchk",
    role: "Participant",
    description:
      "\"The Podcast Fellowship is an eye-opening, ear-opening and heart-opening Jewish learning experience.\"",
  },
  {
    image:
    secondParticipant,
    name: "Chelsea Moses",
    role: "Participant",
    description:
      "\"The idea that I’m getting paid to speak to someone so knowledgeable who wants to share what she knows with me…it’s crazy!\"",
  },
  {
    image:
    thirdParticipant,
    name: "Alek Dernovoy",
    role: "Participant",
    description:
      "\"My advice to other young Jews: Go for it. Start learning, because everybody is at a different step in the journey...It’s been great for me to have this opportunity to grow as a person as well.\"",
  }
];

const howItWorks = [
  {
    image: applyAndChooseImg,
    title: "Apply and choose a timeslot for your intake interview",
  },
  {
    image: meetImg,
    title: "You will be paired with a mentor",
  },
  {
    image: listenImg,
    title: "Listen to a podcast and meet with your mentor to discuss",
  },
  {
    image: paidImg,
    title: "Get paid $15 for each session you complete",
  },
];

export function HomePage() {
  const dispatch = useDispatch();
  const { topics:{ data = [] } } = useSelector(dashboardSelector) as any;

  const isAuthenticated = useIsAuthenticated();

  const rotatedText = "Check out our Jew ish Podcasts.";

  useEffect(() => {
    dispatch(getTopics());
    dispatch(resetState());
  }, []);

  const ReactSlider = Slider as any;

  return (
    <div className={styles["home-page"]}>
      <section className={styles["home-top-section"]}>
        <div className="container mobile-container">
          <div className={`${styles["row-mobile-reverce"]} row`}>
            <div className="col-lg-6">
              <div className={styles["top-text-box"]}>
                <h1>
                  <span className="orange-yellow-color">Listen.</span> Share.{" "}
                  <br />
                  Earn.<span className={styles["fw-300"]}> (Repeat)</span>
                </h1>
                <p>
                  <span className={styles["fw-600"]}>
                    Earn $15 for every podcast you complete
                  </span>{" "}
                  and discuss with your podcast mentor.
                </p>
              </div>
              <div className="row">
                <div className={`${styles["btn"]} ${isAuthenticated ? "col-lg-7" : "col-lg"}`}>
                  {isAuthenticated ? (
                    <Link to="/podcasts/featured">
                      <Button name="Check out our Jewish Podcasts" onClick={() => {}} />
                    </Link> 
                  )  :  (
                    <Link to="/register">
                      <Button name="Apply now" onClick={() => {}} />
                    </Link>
                  )}
                </div>

                <div className="col-lg">
                  {!isAuthenticated ? (
                    <div className={styles["rotated-text-container"]}>
                      <Link to={"/podcasts/featured"}>
                        <PlayIcon />
                      </Link>
                      <p>
                        {rotatedText.split("").map((char, i) => (
                          <Fragment key={i}>
                            <span style={{ transform: `rotate(${i * 11.5}deg)` }}
                              key={i}
                              className={styles["rotated-text-item"]}
                            >{char}</span>
                          </Fragment>
                        ))}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className={styles["top-img-box"]}>
                <div
                  className={`${styles["img-radius"]} after-border after-border-left`}
                >
                  <img src={require("../../assets/images/shutterstock_1680766591.png")} alt="" />
                  <img src={sliderBg} alt="" className={styles["top-image-bg"]}/>
                  <svg width="0" height="0">
                    <defs>
                      <clipPath id="imgTop">
                        <path d="M40.9743 80.4826C43.9887 57.6365 62.2268 39.8094 85.1356 37.3164L418.591 1.02964C448.172 -2.18934 474 20.9809 474 50.7362V442.95C474 473.169 447.401 496.481 417.443 492.519L43.5516 443.059C16.1823 439.439 -3.07308 414.321 0.538243 386.951L40.9743 80.4826Z" />
                      </clipPath>
                      <clipPath id="imgTopMob">
                        <path d="M24.5035 51.6251C26.6136 35.6329 39.3802 23.1539 55.4164 21.4088L250.123 0.220753C270.829 -2.03254 288.909 14.1866 288.909 35.0153V263.877C288.909 285.03 270.29 301.349 249.319 298.575L31.0221 269.698C11.8636 267.163 -1.61522 249.581 0.912708 230.422L24.5035 51.6251Z" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles["how-it-works"]} row`}>
            <div className={`${styles["how-it-works-title"]} col-12 `}>
              <h3>
                How It <span className="orange-yellow-color">Works</span>
              </h3>
            </div>
            {
              howItWorks.map((item, index) => (
                <div
                  className={`${styles["how-it-works-item"]} col-md-3 flex-column d-flex align-items-center`}
                  key={index}
                >
                  <section className={`${styles["how-it-works-img"]}`}>
                    <img src={item.image} alt="NO IMAGE" />
                    <span className={`${styles["how-it-works-img-count"]}`}>{index + 1}</span>
                  </section>
                  <p>{item.title}</p>
                </div>
              ))
            }
          </div>

          <div className={`${styles["slider-first"]}`}>
            <SliderFirst list={data} />
          </div>
        </div>
        <div className={`${styles["reviews-carusel"]}`}>
          <div className={`${styles["mob-top"]}`}></div>
          <ReactSlider className="rewiev-carusel" {...settings}>
            {slides.map((s, i) => (
              <SliderItem key={i} {...s} />
            ))}
          </ReactSlider>
        </div>
      </section>
      <SessionTimeout />
    </div>
  );
}

export default HomePage;
